<template>
  <div
    class="listItem"
    @click="
      $router.push(
        `/${
          $store.state.platform == 'pc' ? 'GoodsDetail' : 'Detail_Mobile'
        }?id=${detail.spuId}`
      )
    "
  >
    <div class="pic">
      <img class="regulars" :src="detail.cover" />
      <template v-if="!isInLike">
        <img
          class="like"
          :src="
            detail.isCollect == 0
              ? require('../../assets/icon/like.png')
              : require('../../assets/icon/liked.png')
          "
          @click.stop="
            $bus.$emit('addLike', detail);
            $emit('getList');
          "
        />
        <!-- <img
          class="basket"
          src="../../assets/icon/basket.png"
          @click.stop="$bus.$emit('addCart', detail.skus[0].skuId)"
        /> -->
      </template>
      <template v-else>
        <img
          class="like"
          src="../../assets/icon/close.png"
          @click.stop="$bus.$emit('removeCollect', detail.collectId)"
        />
        <!-- <img
          class="basket"
          src="../../assets/icon/basket.png"
          @click.stop="$bus.$emit('addCart', detail.skus[0].skuId)"
        /> -->
      </template>
    </div>

    <div class="name">{{ detail.spuName }}</div>
    <div class="buttons">
      <div class="price">€{{ detail.price }}</div>
      <div class="btn deep" @click.stop="handleAddCart">
        {{ $t("goodDetail.addCart") }}
      </div>
    </div>
    <div @click.stop="() => {}">
      <el-dialog
        title="Talla"
        :visible.sync="listVisible"
        width="30%"
        :before-close="handleClose"
      >
        <div class="list-content">
          <p
            class="list-sku"
            v-for="(item, index) in detail.skus"
            :key="index"
            @click.stop="
              () => {
                $bus.$emit('addCart', item.skuId || item.sku_id);
                listVisible = false;
              }
            "
          >
            {{
              item.specInfo
                ? item.specInfo.replace(/Talla:/g, "")
                : item.spec_info
                ? item.spec_info.replace(/Talla:/g, "")
                : ""
            }}
          </p>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListItem",
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isInLike: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    listVisible(val) {
      console.log(this.detail);
    },
  },
  data() {
    return {
      listVisible: false,
    };
  },
  created() {},
  methods: {
    handleClose(done) {
      done();
    },
    handleAddCart() {
      console.log(this.detail);
      if (this.detail.skus.length == 1) {
        this.$bus.$emit(
          "addCart",
          this.detail.skus[0].skuId || this.detail.skus[0].sku_id
        );
      } else {
        this.listVisible = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.listItem {
  width: 100%;

  .pic {
    position: relative;
    height: 29.1vw;
    background: #f2f2f4;
    cursor: pointer;
    .regulars {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 80%;
      max-height: 80%;
      display: inline-block;
      vertical-align: middle;
      margin: auto;
    }
    & > img.like {
      width: 24px;
      height: 22px;
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }

    & > img.basket {
      width: 24px;
      height: 22px;
      position: absolute;
      top: 92px;
      right: 30px;
      cursor: pointer;
    }
  }

  .name {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    height: 50px;
    margin: 40px 0 8px;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    .price {
      font-size: 18px;
      font-weight: bold;
      line-height: 25px;
      height: 25px;
    }

    .btn {
      height: 30px;
      padding: 5px 20px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #454545;
      text-align: center;
      line-height: 30px;
      font-size: 12px;
      font-weight: 600;
      color: #454545;
      cursor: pointer;
      margin-left: 40px;

      &.deep {
        width: 90px;
        text-align: center;
        line-height: 30px;
        border: 1px solid #ccc;
        border-radius: 3px;
        cursor: pointer;
        &:hover {
          background-color: #454545;
          color: #fff;
          transition: 0.5s ease-in-out;
        }
      }

      &:hover {
        background-color: #021450;
      }
    }
  }

  /deep/.el-dialog__header {
    border-bottom: 1px solid #ccc;
  }

  /deep/.el-dialog__body {
    padding: 10px 20px 20px;
  }

  .list-content {
    width: 100%;

    .list-sku {
      width: 100%;
      border-bottom: 1px solid #eee;
      line-height: 35px;
      height: 35px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 500px) {
  /deep/.el-dialog {
    width: 60% !important;
  }
  .listItem {
    .pic {
      height: 55vw !important;

      & > img.like {
        width: 4vw;
        height: 3.6vw;
        position: absolute;
        top: 3vw;
        right: 3vw;
        cursor: pointer;
      }

      & > img.basket {
        width: 4vw;
        height: 4vw;
        position: absolute;
        top: 10vw;
        right: 3vw;
        cursor: pointer;
      }
    }

    .name {
      font-size: 4vw;
      font-weight: 600;
      margin: 3vw 0 1vw;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;

      .price {
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
        height: 25px;
        margin: 20px 0 0;
      }

      .btn {
        height: 30px;
        padding: 5px 20px;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #123178;
        text-align: center;
        line-height: 30px;
        font-size: 12px;
        font-weight: 600;
        color: #123178;
        cursor: pointer;
        margin-left: 0px;

        &.deep {
          background-color: #e8c9a0;
          border: 1px solid #e8c9a0;
          color: #fff;

          &:hover {
            background-color: #eecc77;
          }
        }

        &:hover {
          background-color: #021450;
        }
      }
    }
  }
}
</style>
